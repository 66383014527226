import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Smallstep `}<a parentName="p" {...{
        "href": "https://smallstep.com/certificate-manager"
      }}>{`Certificate Manager`}</a>{` is a commercial product that delivers a managed certificate authority (CA) capable of issuing private x.509 TLS certificates. `}</p>
    <p>{`Certificate Manager builds on two open-source projects, maintained by smallstep:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`step-ca`}</inlineCode>{`: a private online certificate authority for secure automated certificate management.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`step`}</inlineCode>{`: a general-purpose cryptography toolkit and the client-side counterpart to `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{`.
With Certificate Manager, you can create your own private CA to manage certificates on internal services, websites, infrastructure, people, or devices. `}</li>
    </ul>
    <h3>{`Getting started with Certificate Manager`}</h3>
    <ol>
      <li parentName="ol">{`Create a Team and Team Admin`}</li>
      <li parentName="ol">{`Create an Authority`}</li>
      <li parentName="ol">{`Install `}<inlineCode parentName="li">{`step`}</inlineCode>{` on your client`}</li>
      <li parentName="ol">{`Configure your client for your Authority`}</li>
    </ol>
    <h3>{`Before you begin`}</h3>
    <p>{`This tutorial assumes you are setting up Certificate Manager.`}</p>
    <p>{`If you need SSH certificates instead, see our `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/ssh"
      }}>{`documentation for Smallstep SSH`}</a>{`.`}</p>
    <p>{`Have questions? `}<a parentName="p" {...{
        "href": "mailto:support@smallstep.com"
      }}>{`Contact`}</a>{` Customer Success.`}</p>
    <h3>{`step 1 - Create a Smallstep Team`}</h3>
    <p>{`Creating a team gives you access to Smallstep's products.
Click `}<a parentName="p" {...{
        "href": "https://smallstep.com/signup?product=cm"
      }}>{`here`}</a>{` to create a team.
You will be asked to provide:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Team Name`}</inlineCode>{` - Usually, this is your company name. `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Team URL`}</inlineCode>{` - This is where you will access the smallstep dashboard and will also be the base domain for the CA URL for any Authorities you create. `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`First & Last Name`}</inlineCode>{` - Smallstep Team administrator's name. `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`E-mail`}</inlineCode>{` - Smallstep Team administrator's e-mail address.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`password`}</inlineCode>{` - This password is used to login into the Smallstep dashboard`}</li>
    </ul>
    <p>{`Smallstep team admins can subscribe to and manage Smallstep products.`}</p>
    <h3>{`step 2 - Create an Authority`}</h3>
    <p>{`A Certificate Manager Authority is an online CA that authenticates and authorizes certificate requests.
It can issue, renew, and revoke your x.509 TLS certificates.
To create an Authority:`}</p>
    <ul>
      <li parentName="ul">{`Log into the smallstep dashboard, select the Certificate Manager tab, and click the "Add Authority" button.`}</li>
      <li parentName="ul">{`Choose "Create a new hosted Authority".`}</li>
      <li parentName="ul">{`Give your Authority a name and subdomain value (the URL path you wish to use for your online CA).`}</li>
      <li parentName="ul">{`Choose "Create"
Behind the scenes, Certificate Manager creates a new root and an online intermediate CA,
storing the private keys into `}<a parentName="li" {...{
          "href": "https://cloud.google.com/security-key-management"
        }}>{`Google's Cloud KMS`}</a>{`.`}</li>
    </ul>
    <Alert severity="info" mdxType="Alert">
  <div>
    If you are looking to import an existing Root or to set up Smallstep as a subordinate or intermediate CA, please contact <a href="mailto:support@smallstep.com">Customer Success</a>.
  </div>
    </Alert>
    <p>{`On the Authority detail page, you will see the CA URL and Fingerprint used to interact with your CA.`}</p>
    <p>{`Certificate Manager also creates a default provisioner called `}<inlineCode parentName="p">{`authority-admin`}</inlineCode>{`,
connected to your smallstep login.
You can use this provisioner to administer the Authority and to get certificates.
See `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/certificate-manager/basic-ops"
      }}>{`basic certificate operations`}</a>{` for examples. `}</p>
    <p>{`An `}<em parentName="p">{`authority super admin`}</em>{` account is also created, using your e-mail address as the admin name/subject.
The super admin can manage other authority admins.`}</p>
    <h3>{`Step 3 - Install `}<inlineCode parentName="h3">{`step`}</inlineCode></h3>
    <p>{`To interact with Certificate Manager, you will need our `}<inlineCode parentName="p">{`step`}</inlineCode>{` CLI command on your local machine.
`}<inlineCode parentName="p">{`step`}</inlineCode>{` acts as a front-end interface to Certificate Manager and is used for many common crypto and X.509 operations.
It's trivial to install the step binary on your local machine.
The instructions are `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/step-cli/installation"
      }}>{`here`}</a>{`.`}</p>
    <h3>{`Step 4 - Configure your client`}</h3>
    <p>{`Certificate Manager authorities are administered using the `}<inlineCode parentName="p">{`step`}</inlineCode>{` CLI command.
To connect your local client with the hosted Authority, you need to bootstrap into the PKI.
Run the following command, substituting the values from your Authority's properties:`}</p>
    <CodeBlock language="shell-session" copyText="step ca bootstrap --ca-url [YOUR CA URL] --fingerprint [YOUR AUTHORITY FINGERPRINT]" mdxType="CodeBlock">
      {`$ step ca bootstrap --ca-url [YOUR CA URL] --fingerprint [YOUR AUTHORITY FINGERPRINT]
`}
    </CodeBlock>
    <p>{`(You can always find this command on the Quick Actions section on your authority page.)`}</p>
    <p>{`This command will download the CA Root certificate and configure your local `}<inlineCode parentName="p">{`step`}</inlineCode>{` client to interact with the Authority.`}</p>
    <p>{`If desired, you can also use the `}<inlineCode parentName="p">{`step`}</inlineCode>{` CLI to `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/step-cli/reference/certificate/install"
      }}>{`install`}</a>{` the CA Root certificate to your system's truststore.`}</p>
    <h2>{`Next Steps`}</h2>
    <ul>
      <li parentName="ul">{`With your initial configuration complete, it's time to explore `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/certificate-manager/basic-ops"
        }}>{`basic certificate operations`}</a>{`.`}</li>
      <li parentName="ul">{`Need help getting started? `}<a parentName="li" {...{
          "href": "mailto:support@smallstep.com"
        }}>{`Contact`}</a>{` our Customer Success team. `}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      